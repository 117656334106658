import GPT3Tokenizer from 'gpt3-tokenizer';
import 'core-js';

import './jquery-3.6.4.min.js';
import './pdf.js';
import './pdf.worker.js';

import fileImage from '../images/file.png';
import logoUserImage from '../images/logo.png';
import logoGPTImage from '../images/logo_chatgpt.png';
import logoWebsiteImage from '../images/website.png';

const tokenizer = new GPT3Tokenizer({ type: 'gpt3' });

var keyAPI = localStorage.getItem("keyAPI");
var premiumMember = localStorage.getItem("premium_status");

var context = [];
var fileName;
var fileSize;
var allTextFromFiles = '';
var fileNumber = 0;
var numPages;
var totalNumPages = 0;
var messages = [{
  'role': 'system',
  'content': 'Vous êtes un utilisateur'
}];
var jsonData = {
  'model': 'gpt-3.5-turbo-16k',
  'messages': messages,
  'temperature': 0.8,
  'max_tokens': 1000,
  'top_p': 1,
  'frequency_penalty': 0,
  'presence_penalty': 0.3,
};

//CHECK BROWSER
function checkBrowser() {
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  if (isChrome) {
    $('#checkBrowser').css('display', 'none');
  } else {
    $('#container').css('display', 'none');
    $('#checkBrowser').css('display', 'flex');
  }
}
checkBrowser();

//CONFIRM LEAVE
function confirmLeavePage() {
  window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    e.returnValue = '';
  });

  window.addEventListener('unload', function () {
    alert("Are you sure you want to leave this page? Your information will not be saved.");
  });
}

//LOAD LOCAL STORAGE
window.onload = function() {
  $('#loading').fadeOut();
  if(keyAPI){
    $('#containerInput').addClass('containerActive');
    $('#inputAPIKey').val(keyAPI);
    $('.sectionHello').remove();
    $('#navbarMenuNotActivated').css('display', 'flex');
    $('#navbarMenuActivated').css('display', 'none');
  }
  else{
    $('#navbarMenuNotActivated').css('display', 'flex');
    $('#navbarMenuActivated').css('display', 'none');
  }
  if(premiumMember){
    $('#containerInputURL h6').remove();
    $('#sidebarLeftpremium').css('display', 'none');
    $('#sidebarLeftpremiumActivate').css('display', 'flex');
    $('#premium').css('display', 'none');
    $('#premiumActivate').css('display', 'flex');
    $('.sectionHello').remove();
    $('#navbarMenuNotActivated').css('display', 'none');
    $('#navbarMenuActivated').css('display', 'flex');
  }
};

//DOCUMENT READY
$(document).ready(function() {
  //FAQ
  $('.sectionFAQContain div:first-of-type').click(function() {
    $(this).parent().toggleClass('sectionFAQContainActive');
  });

  //TEST API KEY
  $('#buttonAPIKey').click(function() {
    var apiKey = $('#inputAPIKey').val();
    testAPIKey(apiKey);
  });
  function testAPIKey(apiKey) {
    $.ajax({
      url: 'https://api.openai.com/v1/engines',
      headers: {
        'Authorization': 'Bearer ' + apiKey,
        'Content-Type': 'application/json'
      },
      method: 'GET',
      success: function(response) {
        $('<p class="containerAPIKeyInfo">Valid API key</p>').appendTo('#containerAPIKey');
        $('#inputAPIKey').css('border', '1px solid #324bb7');
        setTimeout(function() {
          $('#containerAPI').css('display', 'none');
          var message = $('#chatInput textarea').val();
          processUserInput(message);
        }, 1000);
        keyAPI = $('#inputAPIKey').val();
        localStorage.setItem("keyAPI", keyAPI);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        $('<p class="containerAPIKeyInfo">Invalid API key</p>').appendTo('#containerAPIKey');
        $('#inputAPIKey').css('border', '1px solid #b02929');
        $('#containerCreateChat').removeClass('containerActive');
      }
    });
  }

  //PREMIUM INPUT
  $('#buttonPremium').click(function() {
    var inputValue = $('#inputPremium').val();
    if( inputValue == 'PRa129Yr£23'){
      localStorage.setItem("premium_status", "true");
      $('#containerInputURL h6').remove();
      $('#premiumActivate').css('display', 'flex');
      $('#premium').css('display', 'none');
      $('#premium h6').remove();
      $('.sectionHello').remove();
      location.reload();
    }
    else{
      $('#premium h6').css('display', 'inline-flex');
    }
  });

  //RECUP TEXT FROM URL
  $('#uploadURL').click(function() {
    var url = $('#urlInput').val();
    var proxyUrl = 'https://api.allorigins.win/get?url=' + encodeURIComponent(url);
    $('.loader').css('display', 'inline-flex');
    $('.loader img').css('display', 'inline-block');
    $('.containerInputURLContainer').css('opacity', '0.5');
    $('.containerInputURLContainer').css('pointer-events', 'none');
    $('#containerInputURL .loader p').css('color', '#ffffff');
    $('#containerInputURL .loader p').text('Loading ...');
    $.getJSON(proxyUrl, function(data) {
      try {
        if (!data.contents) {
          $('.loader').css('display', 'none');
          $('.loader img').css('display', 'none');
          $('.containerInputURLContainer').css('opacity', '1');
          $('.containerInputURLContainer').css('pointer-events', 'initial');
          $('#containerInputURL .loader p').css('color', '#ad4747');
          $('#containerInputURL .loader p').text('Try with a a valid URL starting with https://www.');
          return false;
        }
        var contents = data.contents;
        contents = contents.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');
        contents = contents.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
        contents = contents.replace(/<[^>]+>/g, '');
        contents = contents.replace(/[\r\n]+/g, ' ');
        contents = contents.replace(/\s{2,}/g, ' ');
        var text = contents.trim();
        const encoded = tokenizer.encode(text);
        const totalTokens = encoded.bpe.length;
        fileNumber = fileNumber + 1;
        $('<div class="tableDataRow fileNumber'+ fileNumber +'" ><p>'+ url +'</p><p class=tokenCount>'+ totalTokens +' tokens</p><svg xmlns="http://www.w3.org/2000/svg" width="113" height="128" viewBox="0 0 113 128" fill="none"><path d="M101.25 15H78.9538L76.5163 7.6905C74.9818 3.09075 70.6932 0 65.8447 0H46.6553C41.8065 0 37.5183 3.09075 35.9803 7.6905L33.5455 15H11.25C5.0465 15 0 20.0465 0 26.25V33.75C0 37.927 3.76025 37.5 7.79575 37.5C42.4342 37.5 73.8395 37.5 108.75 37.5C110.823 37.5 112.5 35.8228 112.5 33.75V26.25C112.5 20.0465 107.453 15 101.25 15ZM41.4533 15L43.0957 10.0635C43.6085 8.529 45.0403 7.5 46.6553 7.5H65.8447C67.4597 7.5 68.8917 8.529 69.4007 10.0635L71.045 15H41.4533Z" fill="white"></path><path d="M8.41602 45.5L14.3953 117.768C14.9263 123.602 19.742 128 25.5978 128H86.9015C92.7573 128 97.573 123.602 98.1075 117.735L104.084 45.5H8.41602ZM37.4995 109.25C37.4995 114.193 29.9995 114.214 29.9995 109.25V56.75C29.9995 51.8067 37.4995 51.7865 37.4995 56.75V109.25ZM59.9995 109.25C59.9995 114.193 52.4995 114.214 52.4995 109.25V56.75C52.4995 51.8067 59.9995 51.7865 59.9995 56.75V109.25ZM82.4995 109.25C82.4995 114.193 74.9995 114.214 74.9995 109.25V56.75C74.9995 54.6772 76.6768 53 78.7495 53C80.8223 53 82.4995 54.6772 82.4995 56.75V109.25Z" fill="white"></path></svg></div>').appendTo('#tableData');
        checkTotalToken();
        $('<p class=fileNumber'+ fileNumber +' data-url='+ url +'>'+ text +'</p>').appendTo('#fileContainer');
        $('<b data-file-number='+ fileNumber +'>'+ url +'</b>').appendTo('#sidebarLeftTop p:last-of-type');
        $('#urlInput').val('');
        checkPremiumStatut('uploadWebsite');
        $('.loader').css('display', 'none');
        $('.loader img').css('display', 'inline-block');
        $('.containerInputURLContainer').css('opacity', '1');
        $('.containerInputURLContainer').css('pointer-events', 'initial');
        $('#containerInputURL .loader p').css('color', '#ffffff');
        $('#containerInputURL .loader p').text('Loading ...');
        $('#tableData h3').remove();
        $('#containerData').addClass('containerActive');
      } catch (error) {
        $('.loader').css('display', 'inline-flex');
        $('.loader img').css('display', 'none');
        $('.containerInputURLContainer').css('opacity', '1');
        $('.containerInputURLContainer').css('pointer-events', 'initial');
        $('#containerInputURL .loader p').css('color', '#ad4747');
        $('#containerInputURL .loader p').text('Try with a a valid URL starting with https://www.');
      }
    })
  });

  //UPLOAD FILE
  $('#uploadFile').change(function(e) {
    fileNumber = fileNumber + 1;
    var $newInput = $(this).clone().addClass('fileNumber' + fileNumber);
    $('#fileContainer').append($newInput);
    var file = e.target.files[0];
    fileName = file.name;
    fileSize = file.size;
    $('#tableData h3').remove();
    $('#containerData').addClass('containerActive');
    if(keyAPI){
      $('#containerCreateChat').addClass('containerActive');
    }
    $(this).val('');
    $('#fileContainer input[type="file"]').last().each(function() {
      var fileInput = this;
      var file = fileInput.files[0];
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function() {
        var typedarray = new Uint8Array(reader.result);
        pdfjsLib.getDocument({ data: typedarray }).promise.then(function(pdf) {
          numPages = pdf.numPages;
          var pageCount = 0;
          var totalTokenCount = 0;
          var allPagesText = "";
          for (var pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            pdf.getPage(pageNumber).then(function(page) {
              return page.getTextContent().then(function(textContent) {
                var text = textContent.items.map(function(item) {
                  return item.str;
                }).join('');
                allPagesText += text;
                pageCount++;
                if (pageCount === numPages) {
                  const encoded = tokenizer.encode(allPagesText);
                  const totalTokens = encoded.bpe.length;
                  //console.log(totalTokens);
                  //console.log(allPagesText);
                  $('<div class="tableDataRow fileNumber'+ fileNumber +'" ><p>'+ fileName +'</p><p class=tokenCount>'+ totalTokens +' tokens</p><svg xmlns="http://www.w3.org/2000/svg" width="113" height="128" viewBox="0 0 113 128" fill="none"><path d="M101.25 15H78.9538L76.5163 7.6905C74.9818 3.09075 70.6932 0 65.8447 0H46.6553C41.8065 0 37.5183 3.09075 35.9803 7.6905L33.5455 15H11.25C5.0465 15 0 20.0465 0 26.25V33.75C0 37.927 3.76025 37.5 7.79575 37.5C42.4342 37.5 73.8395 37.5 108.75 37.5C110.823 37.5 112.5 35.8228 112.5 33.75V26.25C112.5 20.0465 107.453 15 101.25 15ZM41.4533 15L43.0957 10.0635C43.6085 8.529 45.0403 7.5 46.6553 7.5H65.8447C67.4597 7.5 68.8917 8.529 69.4007 10.0635L71.045 15H41.4533Z" fill="white"/><path d="M8.41602 45.5L14.3953 117.768C14.9263 123.602 19.742 128 25.5978 128H86.9015C92.7573 128 97.573 123.602 98.1075 117.735L104.084 45.5H8.41602ZM37.4995 109.25C37.4995 114.193 29.9995 114.214 29.9995 109.25V56.75C29.9995 51.8067 37.4995 51.7865 37.4995 56.75V109.25ZM59.9995 109.25C59.9995 114.193 52.4995 114.214 52.4995 109.25V56.75C52.4995 51.8067 59.9995 51.7865 59.9995 56.75V109.25ZM82.4995 109.25C82.4995 114.193 74.9995 114.214 74.9995 109.25V56.75C74.9995 54.6772 76.6768 53 78.7495 53C80.8223 53 82.4995 54.6772 82.4995 56.75V109.25Z" fill="white"/></svg></div>').appendTo('#tableData');
                  checkTotalToken();
                  $('<b data-file-number='+ fileNumber +'>'+ fileName +'</b>').appendTo('#sidebarLeftTop p:last-of-type');
                  checkPremiumStatut('uploadFile');
                }
              });
            });
          }
        });  
      };
    });
  });
  function checkTotalToken() {
    var totalTokenCountGlobal = 0;
    if($('.tableDataRow').length == 0){
      $('#TokenCountMessage').removeClass('active');
    }
    else{
      $('.tableDataRow').each(function() {
        var tokenCountText = parseInt($(this).find('.tokenCount').text());
        totalTokenCountGlobal += tokenCountText;
        if (totalTokenCountGlobal > 16000) {
          $('#TokenCountMessage').addClass('active');
          $('#containerCreateChat').removeClass('containerActive');
        } else {
          $('#TokenCountMessage').removeClass('active');
          $('#containerCreateChat').addClass('containerActive');
        }
      });
    }
  }

  //REMOVE FILE OR WEBSITE
  $(document).on('click', '.tableDataRow svg', function() {
    var parentClass = $(this).parent().attr('class');
    var fileNumber = parentClass.split(' ').filter(function(className) {return className.startsWith('fileNumber');})[0];
    fileNumber = parseInt(fileNumber.substring(10));
    $('#fileContainer .fileNumber' + fileNumber).remove();
    $('b[data-file-number='+ fileNumber +']').remove();
    $(this).parent().remove();
    checkPremiumStatut('uploadFile');
    checkDataRows();
    checkTotalToken();
  });
  function checkDataRows() {
    var dataRowsCount = $('.tableDataRow').length;
    if (dataRowsCount === 0) {
      $('#tableData').append('<h3>Upload some datas 💾</h3>');
      $('#containerData').removeClass('containerActive');
      $('#containerCreateChat').removeClass('containerActive');
    }
  }

  //CREATE CHAT
  $('#dataSetName').on('keyup', function() {
    if ($(this).val() === '') {
      $('.containerName .button_primary').removeClass('active');
    } else {
      $('.containerName .button_primary').addClass('active');
    }
  });
  $('#startChat').click(function() {
    var dataSetName = $('#dataSetName').val();
    $('#sidebarLeftTop p span').text(dataSetName);
    $('#sidebarLeftTop p').css('display', "flex");
    $('#dashboard').addClass('chatOpen');
    $('#sidebarLeft').css('display', 'flex');
    $('body').css('overflow', 'hidden');
    $('body').css('height', '100vh');

    confirmLeavePage();

    var completedIterations = 0;
    var totalInputs = $('#fileContainer input[type="file"]').length;
    var completedIterationsWebiste = 0;
    var totalWebsite = $('#fileContainer p').length;

    function processElements() {
      var fileInputsProcessed = false;
      var paragraphsProcessed = false;
      if(totalInputs == 0){
        fileInputsProcessed = true;
      }
      else{
        $('#fileContainer input[type="file"]').each(function() {
          var fileInput = this;
          var file = fileInput.files[0];
          fileName = file.name;
          fileSize = file.size;
          var reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = function() {
            var typedarray = new Uint8Array(reader.result);
            pdfjsLib.getDocument({ data: typedarray }).promise.then(function(pdf) {
              // LOADING
              numPages = pdf.numPages;
              totalNumPages += numPages + 1;
              // LOAD PDF
              var pageCount = 0;
              var totalTokenCount = 0;
              var allPagesText = "";
              for (var pageNumber = 1; pageNumber <= numPages; pageNumber++) {
                pdf.getPage(pageNumber).then(function(page) {
                  return page.getTextContent().then(function(textContent) {
                    var text = textContent.items.map(function(item) {
                      return item.str;
                    }).join('');
                    allPagesText += text;
                    var tokensPerCharacter = 0.33;
                    var characterCount = text.length;
                    var tokenCount = Math.round(characterCount * tokensPerCharacter);
                    totalTokenCount += tokenCount;
                    pageCount++;
                    if (pageCount === numPages) {
                      var prompt = `Document Information:
                      - Name: <docName>${fileName}</docName>
                      - Size: <docSize>${fileSize}</docSize>
                      - Content: <docContent>${allPagesText}</docContent>`;
                      allTextFromFiles += prompt;
                      completedIterations++;
                      if (completedIterations === totalInputs) {
                        fileInputsProcessed = true;
                        checkAllProcessed();
                      }
                    }
                  });
                });
              }
            });
          };
          $('<li><span style="background-image: url(\'' + fileImage + '\');"></span><p>'+ fileName +'</p></li>').appendTo('#chatMessageUserOnBoarding');
        });
      }
      if(totalWebsite == 0){
        paragraphsProcessed = true;
      }
      else{
        $('#fileContainer p').each(function() {
          var text = $(this).text();
          var url = this.getAttribute('data-url');
          var prompt = 
          `Webiste Information:
          - URL: <urlWebsite>${url}</urlWebsite>
          - Content: <docContent>${text}</docContent>`;
          allTextFromFiles += prompt;
          completedIterationsWebiste++;
          if (completedIterationsWebiste === totalWebsite) {
            paragraphsProcessed = true;
            checkAllProcessed();
          }
          $('<li><span style="background-image: url(' + logoWebsiteImage + ');"></span><p>'+ url +'</p></li>').appendTo('#chatMessageUserOnBoarding');
        });
      }
      function checkAllProcessed() {
        if (fileInputsProcessed && paragraphsProcessed) {
          context.push(allTextFromFiles);
          setTimeout(function() {
            $('.chatGPTOnboarding p').css('display', 'block');
            $('.chatGPTOnboarding ul').css('display', 'block');
            $('.chatGPTOnboarding b').css('display', 'none');
          }, 1500);          
        }
      }
    }
    processElements();
  });

  //CREATE A CONV CHAT GPT
  $('#chatInput svg').click(function(e) {
    var message = $('#chatInput textarea').val();
    $('<div class="chatMessage chatUser"><div class="chatMessageLogo"><img src="'+ logoUserImage +'" alt=""></div><p>'+ message +'</p></div>').appendTo('.chatContainer');
    $('#chatInput textarea').val('');
    $('#chatInput textarea').css('height', '47px');
    if (messages.length === 1 ){
      $('#containerAPI').css('display', 'flex');
      return false;
    }
    else{
      processUserInput(message);
    }
    checkPremiumStatut('sendMessage');
  });
});

//OPTIONS ON TEXTAREA
$('#chatInput textarea').on('keydown', function(event) {
  handleKeyDown(event);
});
$('#chatInput textarea').on('keyup', function(event) {
  handleKeyDown(event);
});
function handleKeyDown(event) {
  var textarea = $("#chatInput textarea");
  var textareaValue = $("#chatInput textarea").val();
  adjustTextareaHeight(textarea[0]);

  if(textareaValue != ''){
    $('#chatInput svg').css('background-color', '#5bc083');
    $('#chatInput svg').css('pointer-events', 'initial');
    $('#chatInput svg').css('opacity', '1');
  }
  else{
    $('#chatInput svg').css('background-color', '#40414F');
    $('#chatInput svg').css('pointer-events', 'none');
    $('#chatInput svg').css('opacity', '0.2');
  }

  if (event.shiftKey && event.keyCode === 13) {
    textarea.val(textarea.val() + "\n");
    event.preventDefault();
  } else if (event.keyCode === 13) {
    event.preventDefault();
    var message = textarea.val().trim();
    if (message !== "") {
      $('<div class="chatMessage chatUser"><div class="chatMessageLogo"><img src="'+ logoUserImage +'" alt=""></div><p>'+ message +'</p></div>').appendTo('.chatContainer');
      textarea.val('');
      if (messages.length === 1 ){
        $('#containerAPI').css('display', 'flex');
        return false;
      }
      else{
        processUserInput(message);
      }
      checkPremiumStatut('sendMessage');
    }
  }
}

function adjustTextareaHeight(textarea) {
  textarea.style.height = "47px";
  textarea.style.height = (textarea.scrollHeight > 47 ? textarea.scrollHeight : 47) + "px";
}

function processUserInput(message) {
  if (messages.length === 0 || messages[messages.length - 1].role !== 'user') {
    var prevMessages = context.join(' ');
    messages.push({
      'role': 'user',
      'content': prevMessages
    });
  }
  messages.push({
    'role': 'user',
    'content': message
  });
  $.ajax({
    url: 'https://api.openai.com/v1/chat/completions',
    headers: {
      'Authorization': 'Bearer ' + keyAPI,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    data: JSON.stringify(jsonData),
    beforeSend: function() {
      $('<div class="chatMessage chatGPT"><div class="chatMessageLogo"><img src="'+ logoUserImage +'" alt=""></div><p><b></b></p></div>').appendTo('.chatContainer');
    },
    success: function(response) {
      $('.chatGPT:last-of-type').remove();
      var completion = response.choices[0].message.content;
      $('<div class="chatMessage chatGPT"><div class="chatMessageLogo"><img src="'+ logoUserImage +'" alt=""></div><p>'+ completion +'</p></div>').appendTo('.chatContainer');
      context.push(message);
    },
    error: function(jqXHR, textStatus, errorThrown) {
      $('.chatGPT:last-of-type').remove();
      console.log('Erreur:', jqXHR, textStatus, errorThrown);
      var response = JSON.parse(jqXHR.responseText);
      var errorMessage = response.error.message;
      if (errorMessage.includes("You exceeded your current quota")) {
        $('<div class="chatMessage chatGPT"><div class="chatMessageLogo"><img src="'+ logoGPTImage +'" alt=""></div><p class="errorChatGPT">You exceeded your current quota on your OpenAI Account. You need to manage your usage here:</p><a href="https://platform.openai.com/account/usage" target="_blank">👉 Usage OpenAI</a><em>Two solutions for you:</em><ul class="listsMessage"><li>- Set a payment methods on your OpenAI Account: <a href="https://platform.openai.com/account/billing/payment-methods" target="_blank">Here</a></li><li>- Waiting for your monthly free credits on OpenAI        </li></ul></div>').appendTo('.chatContainer');
      }
      else{
        $('<div class="chatMessage chatGPT"><div class="chatMessageLogo"><img src="'+ logoGPTImage +'" alt=""></div><p class="errorChatGPT">'+ errorMessage +'</p></div>').appendTo('.chatContainer');
      }
    }
  });
  var scrollTo = $('.chatContainer').prop('scrollHeight') - $('.chatContainer').height();
  $('.chatContainer').scrollTop(scrollTo);
}

//CHECK PREMIUM STATUT
function checkPremiumStatut(event){
  if(event == "uploadFile" || event == "uploadWebsite"){
    if (localStorage.getItem("premium_status") === "true") {
      return false;
    }
    else{
      var dataRowsCount = $('.tableDataRow').length;
      if (dataRowsCount === 0) {
        $('#premiumInfoFile').removeClass('visible');
        $('#premiumInfoWebsite').removeClass('visible');
      }
      else{
        $('#premiumInfoFile').addClass('visible');
        $('#premiumInfoWebsite').addClass('visible');
      }
    }
  }
  if(event == "sendMessage"){
    if (localStorage.getItem("premium_status") === "true") {
      return false;
    }
    else{
      var messageLenght = $('.chatUser').length;
      if (messageLenght >= 4) {
        $('#premiumInfoMessage').addClass('visible');
        $('#chatInput').css('pointer-events', 'none');
      }
    }
  }
}

//SCROLLBAR
(function(){
  let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  let scrollbarDiv = document.querySelector('.scrollbar');
    if (!isChrome && !isSafari) {
      scrollbarDiv.innerHTML = 'You need Webkit browser to run this code';
    }
})();